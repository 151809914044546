<template>
  <div id="nav" class="container-fluid">
    <div class="row justify-content-between align-items-center">
      <div class="d-flex">
        <h5
          :class="[
            { title: !isLogin, 'title-login': isLogin },
            'mb-0',
            'col-12',
          ]"
        >
          出勤打卡紀錄
        </h5>
        <div class="avatar w-100">
          <div
            v-if="!isLogin"
            class="d-flex align-items-center justify-content-center"
          >
            <span class="login">未登入</span>
          </div>
          <div
            class="logout d-flex align-items-center justify-content-between"
            v-else
            title="登出"
          >
            <!-- css fixed: vuex 狀態(isLogin)跟 setup(user模板)不同步 -->
            <span v-show="user" class="user">{{ user.pe1name }} </span>
            <LogoutOutlined
              v-show="user"
              :style="{ fontSize: '20px', color: 'white' }"
              @click="logout"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import LogoutOutlined from "@ant-design/icons-vue/LogoutOutlined";
export default {
  name: "Nav",
  components: { LogoutOutlined },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLogin = computed(() => store.getters.loginState);
    const user = computed(() => store.state.user1no);
    const readytoLogout = (val) => store.commit("getLogout", val);
    function logout() {
      if (confirm("確定要登出?")) {
        readytoLogout(true);
        sessionStorage.clear();
        store.commit("getLogin", false);
        router.push("/");
        router.go();
      }
    }
    const getLogin = (val) => store.dispatch("login", val);
    watchEffect(() => {
      return isLogin, user;
    });
    if (user.value) {
      getLogin(true);
    } else {
      router.replace("/");
    }
    return {
      isLogin,
      logout,
      user,
    };
  },
};
</script>
<style scoped>
#nav {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: royalblue;
  color: white;
}
.row > div > .title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.row > div > .title-login {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.avatar {
  display: inline-flex;
  white-space: nowrap;
  width: 42px;
  margin-left: 0;
  color: white;
}
.login,
.user {
  margin-top: 1px;
  border: 1px solid white;
  padding: 2px 5px;
  border-radius: 30px;
}
.user {
  margin-right: 10px;
  color: var(--first-color);
  background: white;
}
#nav {
  position: relative;
}
.logout,
.login {
  font-size: 12px;
  position: absolute;
  right: 15px;
}
</style>
