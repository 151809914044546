import { createStore } from "vuex";

export default createStore({
  state: {
    isLogin: false,
    userData: localStorage.getItem("userData"), //store userInfo persistent
    user1no: JSON.parse(sessionStorage.getItem("user1no")) || null, //store user name
    readytoLogout: false, //對應 reportForm page leave
  },
  getters: {
    getUser: (state) => {
      return state.user1no;
    },
    getUserData: (state) => {
      return state.userData;
    },
    loginState: (state) => {
      return state.isLogin;
    },
    logoutState: (state) => {
      return state.readytoLogout;
    },
  },
  mutations: {
    getLogout(state, payload) {
      state.readytoLogout = payload;
    },
    getLogin(state, payload) {
      state.isLogin = payload;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("getLogin", payload);
    },
  },
  modules: {},
});
