<template>
  <!-- unfinished alert style -->
  <!-- <a-alert message="Success Text" type="success" /> -->

  <div class="home container-fluid mt-5">
    <div class="row">
      <h3>請先登入</h3>
    </div>
    <form class="row flex-row mx-3" @submit.prevent="login">
      <div class="col-12 my-3 text-start input-group">
        <transition>
          <label for="account" class="my-1" v-show="labelShow"
            >使用者代號</label
          >
        </transition>
        <input
          name="account"
          type="text"
          class="form-control w-100"
          placeholder="請輸入代號"
          aria-label="請輸入代號"
          v-model="userData.account"
          autocomplete="off"
          @click="labelShow = true"
          required
        />
      </div>
      <div class="col-12 text-start input-group pwd">
        <transition>
          <label for="name" class="my-1" v-show="labelShow2">使用者密碼</label>
        </transition>

        <input
          ref="pwdInput"
          name="pass"
          type="password"
          class="form-control w-100"
          placeholder="請輸入密碼"
          aria-label="請輸入密碼"
          autocomplete="off"
          @focus="labelShow2 = true"
          v-model="userData.pass"
          required
        />
        <EyeFilled
          :style="{ fontSize: '20px' }"
          class="pass-toggle"
          v-show="togglePass"
          @click="handleTogglePass(false)"
        />
        <EyeInvisibleFilled
          :style="{ fontSize: '20px' }"
          class="pass-toggle"
          v-show="!togglePass"
          @click="handleTogglePass(true)"
        />
      </div>
      <div class="col-12 my-2">
        <button type="submit" class="btn btn-primary my-3 w-100 py-2">
          確定
        </button>
      </div>
      <div class="col-12 d-flex">
        <input
          class="form-check-input"
          type="checkbox"
          id="gridCheck"
          v-model="checked"
          :value="checked"
        />
        <label class="form-check-label mx-2" for="gridCheck">記住我</label>
      </div>
    </form>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons-vue";
export default {
  name: "Home",
  components: { EyeFilled, EyeInvisibleFilled },
  setup() {
    const togglePass = ref(false); //icon show/hide
    let pwdInput = ref(null); //control DOM
    const labelShow = ref(false); //label css
    const labelShow2 = ref(false);
    const userData = reactive({ account: null, pass: null });
    const { account, pass } = toRefs(userData);
    const checked = ref(false);
    const router = useRouter();
    const store = useStore();
    const user = computed(() => store.getters.getUser); //user id
    async function login() {
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}type=LOGIN&us1no=${userData.account}&pass=${userData.pass}`,
        {
          method: "POST",
        }
      ).catch((err) => console.log(err));
      const result = await res.json();
      console.log(result);
      if (result.error) {
        alert(result.error);
      } else {
        if (checked.value) {
          saveInfo();
        }
        store.dispatch("login", true);
        sessionStorage.setItem("user1no", JSON.stringify(result));
        router.push("/chooseProject2");
        router.go();
      }
    }
    // 記憶密碼(localStorage)
    function saveInfo() {
      if (account.value && pass.value) {
        localStorage.setItem("userData", JSON.stringify(userData));
      } else {
        console.log("fail to save.");
      }
    }
    function handleTogglePass(val) {
      if (val) {
        pwdInput.value["type"] = "text";
        togglePass.value = val;
      } else {
        pwdInput.value["type"] = "password";
        togglePass.value = val;
      }
    }
    watchEffect(() => {
      if (user.value) {
        router.replace("/chooseProject2");
      }
      return checked;
    });
    onMounted(() => {
      const data = JSON.parse(localStorage.getItem("userData"));
      if (data) {
        labelShow.value = labelShow2.value = true;
        checked.value = true;
        userData.account = data.account;
        userData.pass = data.pass;
      }
    });
    return {
      labelShow,
      labelShow2,
      togglePass,
      userData,
      checked,
      login,
      saveInfo,
      handleTogglePass,
      pwdInput,
    };
  },
};
</script>
<style scoped>
.home {
  margin: auto;
  height: 80vh;
}
.input-group > label {
  font-size: 14px;
  color: var(--first-color);
}
.input-group {
  min-height: 66px;
  display: flex;
  flex-direction: column;
}
/* .pwd {
  position: relative;
} */
.pass-toggle {
  position: absolute;
  right: 10%;
  top: 50%;
  color: var(--input-line);
  z-index: 100;
}
/* label transition css */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
