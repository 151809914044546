import { createRouter, createWebHistory } from 'vue-router';
// import store from "../store";
import Home from '../views/Home.vue';
// meta unfinished
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'slide-right' },
  },
  {
    path: '/chooseProject',
    name: 'ChooseProject',
    component: () => import('../views/ChooseProject.vue'),
    meta: {
      requiresAuth: true,
      transition: 'slide-right',
    },
  },
  {
    path: '/chooseProject2',
    name: 'ChooseProject2',
    component: () => import('../views/ChooseProject2.vue'),
    meta: {
      requiresAuth: true,
      transition: 'slide-right',
    },
  },
  {
    path: '/chooseROC',
    name: 'ChooseROC',
    component: () => import('../views/ChooseROC.vue'),
    meta: {
      requiresAuth: true,
      transition: 'slide-right',
    },
  },
  {
    path: '/reportForm/:no',
    name: 'ReportForm',
    component: () => import('../views/ReportForm.vue'),
    meta: {
      requiresAuth: true,
      transition: 'slide-right',
    },
  },
  {
    path: '/reportForm2/:no/:typeof',
    name: 'ReportForm2',
    component: () => import('../views/ReportForm2.vue'),
    meta: {
      requiresAuth: true,
      transition: 'slide-right',
    },
  },
  {
    // 404
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
