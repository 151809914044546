<template>
  <Nav />
  <router-view />
  <!-- // test 特效 
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition">
      <component :is="Component" />
    </transition>
  </router-view>-->
</template>

<script>
import Nav from "@/components/Nav";
export default {
  components: { Nav },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>